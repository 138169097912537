<template>
  <validation-observer
    ref="studentPoliciesSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="studentPoliciesSaveModal"
      centered
      body-class="position-static"
      no-close-on-backdrop
      :title="isCreated ? 'Thêm Sinh viên hưởng Chế độ chính sách' : 'Cập nhật Sinh viên hưởng Chế độ chính sách'"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>

        <b-form-group
          label="Khóa học"
          label-for="courseId"
        >
          <v-select
            v-model="targetData.courseId"
            :disabled="!isCreated"
            :options="courses"
            :reduce="option => option.value"
            @input="onCoursesChange"
          />
        </b-form-group>
        <b-form-group
          label="Học kỳ"
          label-for="courseSemester_id"
        >
          <v-select
            :disabled="!isCreated"
            v-model="targetData.courseSemesterId"
            :options="courseSemesters"
            :reduce="option => option.value"
          />
        </b-form-group>
        <b-form-group>
          <v-select
            :disabled="!isCreated"
            v-model="targetData.departmentId"
            :options="departments"
            :reduce="option => option.value"
            placeholder="Khoa/Bộ môn"
            @input="onDepartmentChange"
          />
        </b-form-group>
        <b-form-group>
          <v-select
            :disabled="!isCreated"
            v-model="targetData.majorId"
            :options="majors"
            :reduce="option => option.value"
            placeholder="Chuyên ngành"
            @input="onMajorChange"
          />
        </b-form-group>
        <b-form-group>
          <v-select
            :disabled="!isCreated"
            v-model="targetData.classId"
            :options="classes"
            :reduce="option => option.value"
            placeholder="Lớp cố định"
            @input="onClassChange"
          />
        </b-form-group>
        <b-form-group label-for="studentId">
          <template v-slot:label>
            Sinh viên <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Sinh viên"
            rules="required"
          >
            <v-select
              v-model="targetData.studentId"
              :disabled="!isCreated"
              :options="studentsCourse"
              :reduce="option => option.id"
              label="name"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="policyId">
          <template v-slot:label>
            Loại chế độ chính sách <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Loại chế độ chính sách"
            rules="required"
          >
            <v-select
              id="policyId"
              v-model="targetData.policyId"
              :options="policies"
              :reduce="option => option.id"
              label="name"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Trạng thái"
            rules="required"
          >
            <v-select
              v-model="targetData.status"
              :options="statusOptions"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('studentPoliciesSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BModal, BOverlay, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'

export default {
  name: 'StudentPoliciesSave',
  components: {
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    dataSource: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetData: {
        organizationId: getUser().orgId,
        departmentId: null,
        majorId: null,
        classId: null,
        policyId: null,
        studentId: null,
        courseId: null,
        courseSemesterId: null,
        status: 1,
      },
      statusOptions: [
        { value: 1, label: 'Đang hoạt động' },
        { value: 0, label: 'Không hoạt động' },
      ],
      required,
    }
  },
  computed: {
    ...mapGetters({
      courses: 'dropdown/courses',
      courseSemesters: 'dropdown/courseSemesters',
      departments: 'dropdown/departments',
      majors: 'dropdown/majors',
      classes: 'dropdown/classes',
      studentsCourse: 'student/students',
      policies: 'policies/policies',
    }),
    isCreated() {
      return !this.dataSource
    },
  },

  methods: {
    ...mapMutations({
      setCourses: 'dropdown/SET_COURSES',
      setCourseSemester: 'dropdown/SET_COURSE_SEMESTERS',
      setMajors: 'dropdown/SET_MAJORS',
      setClasses: 'dropdown/SET_CLASSES',
      setStudents: 'student/SET_STUDENTS',
    }),
    ...mapActions({
      createData: 'studentPolicies/createStudentPolicies',
      updateData: 'studentPolicies/updateStudentPolicies',
      getCourses: 'dropdown/getCourses',
      getCourseSemesters: 'dropdown/getCourseSemesters',
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
      getClasses: 'dropdown/getClasses',
      getStudentsByCourse: 'student/getStudentsByCourse',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onTrainingSystemsChange(event) {
      this.setCourses([])
      this.filter.courseId = null

      await this.getCourses({
        organizationId: getUser().orgId,
        trainingSystemId: event,
      })
      // this.filter.classId = null
    },
    async onCoursesChange(event) {
      this.setMajors([])
      this.targetData.courseSemesterId = null

      await this.getCourseSemesters({
        organizationId: getUser().orgId,
        courseId: event,
      })
      this.targetData.classId = null
    },
    async onDepartmentChange(event) {
      this.setMajors([])
      this.targetData.majorId = null

      await this.getMajors({
        organizationId: getUser().orgId,
        departmentId: event,
      })
      this.targetData.classId = null
    },
    async onMajorChange(event) {
      this.setClasses([])
      await this.getClasses({
        organizationId: getUser().orgId,
        courseId: this.targetData.courseId,
        departmentId: this.targetData.departmentId,
        majorId: event,
      })
      this.targetData.classId = null
    },
    async onClassChange(event) {
      this.setStudents([])
      await this.getStudentsByCourse({
        organizationId: getUser().orgId,
        trainingSystemId: this.targetData.trainingSystemId,
        courseId: this.targetData.courseId,
        departmentId: this.targetData.departmentId,
        majorId: this.targetData.majorId,
        classId: event,
        currentPage: 1,
        itemsPerPage: 100,
      })
    },
    async onShow() {
      if (this.isCreated) {
        this.targetData.courseId = this.courses[0].value
      } else {
        this.targetData.courseId = this.dataSource.courseId
      }
      if (this.isCreated) {
        this.targetData.departmentId = this.departments[0].value
      } else {
        this.targetData.departmentId = this.dataSource.departmentId
      }

      await Promise.all([
        await this.getCourseSemesters({
          organizationId: getUser().orgId,
          courseId: this.targetData.courseId,
        }),
        await this.getMajors({
          organizationId: getUser().orgId,
          departmentId: this.targetData.departmentId,
        }),
      ])
      if (!this.isCreated && this.dataSource) {
        this.targetData = { ...this.dataSource }
        await Promise.all([
          await this.getClasses({
            organizationId: getUser().orgId,
            trainingSystemId: this.targetData.trainingSystemId,
            courseId: this.targetData.courseId,
            departmentId: this.targetData.departmentId,
            majorId: this.targetData.majorId,
          }),
          await this.getStudentsByCourse({
            organizationId: getUser().orgId,
            trainingSystemId: this.targetData.trainingSystemId,
            courseId: this.targetData.courseId,
            departmentId: this.targetData.departmentId,
            majorId: this.targetData.majorId,
            classId: this.targetData.classId,
            currentPage: 1,
            itemsPerPage: 100,
          }),
        ])
      }
    },
    onHide() {
      this.targetData = {
        organizationId: getUser().orgId,
        trainingSystemId: null,
        departmentId: null,
        majorId: null,
        classId: null,
        policyId: null,
        studentId: null,
        courseId: null,
        courseSemesterId: null,
        status: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .studentPoliciesSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createData(this.targetData)
            : await this.updateData(this.targetData)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('studentPoliciesSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>
